import Vue from 'vue'

export function formatDate (dateAsStr, format) {
  const date  = new Date(dateAsStr)
  const day   =  date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year  =  date.getFullYear()
  switch (format) {
    case 'DD/MM':
      return `${day}/${month}`
    case 'DD/MM/YYYY':
    default:
      return `${day}/${month}/${year}`
  }
}

export function formatMoney (number, suffix = '€') {
  if (Number.isInteger(number)) {
    return parseInt(number, 10) + suffix
  } else {
    return number.toFixed(2) + suffix
  }
}

export const pluralize = (count, singular, plural) => (count >= 2 ? plural : singular)

const plugin = {
  install (Vue) {
    Vue.filter('date', formatDate)
    Vue.filter('money', formatMoney)
    Vue.filter('pluralize', pluralize)
  },
}

Vue.use(plugin)
