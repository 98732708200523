export default (tracker) => ({
  script: [
    {
      hid: 'partner1-tracking-part1',
      innerHTML: `
        var dataLayer = [{ 'environment': 'live' }];
      `,
    },
    {
      hid: 'partner1-tracking-part2',
      type: 'text/javascript',
      src: 'https://masdpanalytics.azureedge.net/main-datalayer.js',
    },
    {
      hid: 'partner1-tracking-part3',
      innerHTML: `
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${tracker.id}');
      `,
    },
  ],
  noscript: [
    {
      hid: 'partner1-tracking-part4',
      pbody: true,
      height: 0,
      width: 0,
      style: 'display:none;visibility:hidden',
      src: `https://www.googletagmanager.com/ns.html?id=${tracker.id}`,
    },
  ],
  __dangerouslyDisableSanitizersByTagID: {
    'partner1-tracking-part1': ['innerHTML'],
    'partner1-tracking-part3': ['innerHTML'],
  },
})
