export const RESET = 'RESET'

export const SET_SECTIONS = 'SET_SECTIONS'
export const SET_ARE_SECTIONS_LOADING = 'SET_ARE_SECTIONS_LOADING'
export const SET_ARE_SECTIONS_LOADED = 'SET_ARE_SECTIONS_LOADED'
export const SET_IDENTIFIER = 'SET_IDENTIFIER'
export const SET_RESELLING = 'SET_RESELLING'

export const SELECT_COUPON = 'SELECT_COUPON'
export const CLOSE_OVERLAY = 'CLOSE_OVERLAY'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
