import partition from 'lodash-es/partition'
import last from 'lodash-es/last'
import uniq from 'lodash-es/uniq'

import airbrake from '../../utils/airbrake'

import resetter from '../../utils/store/resetter'
import createInitialState from './state'

import * as types from './mutation-types'

const CODED_CATEGORIES = [
  'AnalyzedReceipt',
  'CompletedReceipt',
  'DeclaredReceipt',
  'RejectedReceipt',
  'UserReviewedReceipt',
  'FilteredAnalyzedReceipt',
]

const IGNORED_CATEGORIES = []

const FORBIDDEN_CATEGORIES = [
  'AnalyzedReceipt',

  'AnnounceExpirationsAfterComeback',
  'AnnounceNewProgram',
  'TextWithPictureAndButtons',
  'VerticalTextWithImage',
  'WebviewWithButtons',
]

function filterMessages (messages) {
  const [coded, unknown] = partition(messages, m => CODED_CATEGORIES.includes(m.category))

  const forbidden = coded.filter(m => FORBIDDEN_CATEGORIES.includes(m.category))
  if (forbidden.length !== 0) {
    const message = `forbidden message categories : ${uniq(forbidden.map(m => m.category)).join(',')}`
    airbrake.notify(new Error(message))
  }

  const filtered = unknown.filter(m => !IGNORED_CATEGORIES.includes(m.category))
  if (filtered.length !== 0) {
    const message = `unknown message categories : ${uniq(filtered.map(m => m.category)).join(',')}`
    airbrake.notify(new Error(message))
  }

  return coded
}

export default {
  [types.RESET]: resetter(createInitialState),

  [types.APPEND_TIMELINE] (state, payload) {
    state.hasArchives = payload.has_archives
    state.hasBenefitsSummary = payload.has_benefits_summary

    state.messages.push(...filterMessages(payload.messages))
    const lastMessage = last(payload.messages)
    state.oldestSeenTimelineOrderedKey = lastMessage && lastMessage.timeline_ordered_key
  },

  [types.APPEND_ARCHIVES] (state, payload) {
    state.archivedMessages.push(...filterMessages(payload.messages))
    const lastMessage = last(payload.messages)
    state.archivesOldestSeenTimelineOrderedKey = lastMessage && lastMessage.timeline_ordered_key
  },

  [types.SET_IS_TIMELINE_LOADING] (state, boolean) {
    state.isTimelineLoading = boolean
  },
  [types.SET_IS_TIMELINE_LOADED] (state, boolean) {
    state.isTimelineLoaded = boolean
  },
  [types.SET_ARE_ARCHIVES_LOADING] (state, boolean) {
    state.areArchivesLoading = boolean
  },
  [types.SET_ARE_ARCHIVES_LOADED] (state, boolean) {
    state.areArchivesLoaded = boolean
  },
  [types.SET_ARE_ARCHIVES_OPEN] (state, boolean) {
    state.areArchivesOpen = boolean
  },

  [types.SET_IS_MODAL_IMAGE_OPEN] (state, boolean) {
    state.isModalImageOpen = boolean
  },
  [types.SET_MODAL_IMAGE_SRC] (state, modalImageSrc) {
    state.modalImageSrc = modalImageSrc
  },

  // [types.UPDATE_MESSAGE] (state, payload) {
  //   const idx = state.messages.findIndex(m => (m.identifier === payload.old.identifier))
  //   state.messages.splice(idx, 1, payload.new)
  // },
  [types.ARCHIVE_MESSAGE] (state, message) {
    const idx = state.messages.findIndex(m => (m.identifier === message.identifier))
    state.messages.splice(idx, 1)

    if (state.archivedMessages.length === 0) { return }

    // archivedMessages#timeline_ordered_key decreasing with array index
    // => to insert a new message at correct spot, we need to
    // 1. find the first inferior timeline_ordered_key
    // 2. insert just in front of it
    const successorIdx = state.archivedMessages.findIndex(m =>
      (m.timeline_ordered_key < message.timeline_ordered_key)
    )
    state.archivedMessages.splice(successorIdx, 0, message)
  },
}
