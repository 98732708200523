import * as PE from '@purchease/sdk'

import logout from '~/utils/functions/logout'

PE.client.configure({
  region: process.env.REGION_CODE || 'FR',
  environment: process.env.API_ENV || 'development',
})

export default async (ctx, inject) => {
  ctx.$PE = PE
  inject('PE', PE)

  // purchease kit not safe to use server-side
  // because it is a singleton and
  // all users would use the same token
  if (process.server) return

  const clientId = ctx.route.query.clientId
  if (!clientId) { return }

  if (!ctx.$PE.client.isInitialized()) {
    await ctx.$PE.client.init(clientId)
  }

  ctx.$PE.client.onError((error) => {
    ctx.store.commit('auth/setIsRegistrationBlocked', true)
    return Promise.reject(error)
  })

  const isAuthorized = await ctx.$PE.client.isAuthorized()
  if (ctx.store.getters['auth/isRegisteredToPartner'] && !isAuthorized) {
    try {
      await ctx.$PE.client.authorizeUserWithToken({
        token: ctx.store.getters['auth/partnerAccessToken'],
      })
    } catch (error) {
      if (error instanceof ctx.$PE.Error && error.code === ctx.$PE.Error.Codes.UNAUTHORIZED) {
        ctx.store.dispatch('addToastGenericError')
        logout({
          $axios: ctx.$axios,
          $route: ctx.route,
          $airbrake: ctx.$airbrake,
          $store: ctx.store,
          // $router: ctx.app.router, // DO NOT REDIRECT HERE (it breaks things up)
          locator: '$PE setup',
        })
      }
    }
  }
}
