import resetter from '../../utils/store/resetter'
import createInitialState from './state'

import * as types from './mutation-types'

export default {
  [types.RESET]: resetter(createInitialState),

  [types.SET_IS_LOADING] (state, boolean) {
    state.isLoading = boolean
  },
  [types.SET_IS_LOADED] (state, boolean) {
    state.isLoaded = boolean
  },
  [types.SET_ERROR] (state, error) {
    state.error = error
  },

  [types.SET_CARD_SLOT] (state, cardSlot) {
    state.cardSlot = cardSlot
  },
  [types.SET_COUPONS] (state, coupons) {
    state.coupons = coupons
  },
}
