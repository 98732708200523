import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _c6a22c40 = () => interopDefault(import('../pages/assistance.vue' /* webpackChunkName: "pages/assistance" */))
const _f70aa7be = () => interopDefault(import('../pages/catalog.vue' /* webpackChunkName: "pages/catalog" */))
const _81df5928 = () => interopDefault(import('../pages/Iframe.vue' /* webpackChunkName: "pages/Iframe" */))
const _05c1f887 = () => interopDefault(import('../pages/menu.vue' /* webpackChunkName: "pages/menu" */))
const _a1a042d8 = () => interopDefault(import('../pages/terms-of-service1.vue' /* webpackChunkName: "pages/terms-of-service1" */))
const _a18413d6 = () => interopDefault(import('../pages/terms-of-service2.vue' /* webpackChunkName: "pages/terms-of-service2" */))
const _a4c5bc2c = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _52610867 = () => interopDefault(import('../pages/auth/signup.vue' /* webpackChunkName: "pages/auth/signup" */))
const _56f5f4ec = () => interopDefault(import('../pages/errors/client_unauthorized.vue' /* webpackChunkName: "pages/errors/client_unauthorized" */))
const _1f1ec96c = () => interopDefault(import('../pages/errors/demo_program_not_loaded.vue' /* webpackChunkName: "pages/errors/demo_program_not_loaded" */))
const _2a0fb27a = () => interopDefault(import('../pages/errors/missing_client_id.vue' /* webpackChunkName: "pages/errors/missing_client_id" */))
const _3bba3361 = () => interopDefault(import('../pages/errors/no_auto_register.vue' /* webpackChunkName: "pages/errors/no_auto_register" */))
const _47540012 = () => interopDefault(import('../pages/errors/registration_blocked.vue' /* webpackChunkName: "pages/errors/registration_blocked" */))
const _8e879cda = () => interopDefault(import('../pages/profile/earned_coupons.vue' /* webpackChunkName: "pages/profile/earned_coupons" */))
const _c819d412 = () => interopDefault(import('../pages/profile/pot.vue' /* webpackChunkName: "pages/profile/pot" */))
const _914b8f7e = () => interopDefault(import('../pages/profile/shoot.vue' /* webpackChunkName: "pages/profile/shoot" */))
const _ba7e9f78 = () => interopDefault(import('../pages/profile/shop.vue' /* webpackChunkName: "pages/profile/shop" */))
const _4f3fa6dc = () => interopDefault(import('../pages/profile/receipts/_receipt_uuid/review.vue' /* webpackChunkName: "pages/profile/receipts/_receipt_uuid/review" */))
const _3083fff5 = () => interopDefault(import('../pages/index.js' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/assistance",
    component: _c6a22c40,
    name: "assistance"
  }, {
    path: "/catalog",
    component: _f70aa7be,
    name: "catalog"
  }, {
    path: "/Iframe",
    component: _81df5928,
    name: "Iframe"
  }, {
    path: "/menu",
    component: _05c1f887,
    name: "menu"
  }, {
    path: "/terms-of-service1",
    component: _a1a042d8,
    name: "terms-of-service1"
  }, {
    path: "/terms-of-service2",
    component: _a18413d6,
    name: "terms-of-service2"
  }, {
    path: "/auth/login",
    component: _a4c5bc2c,
    name: "auth-login"
  }, {
    path: "/auth/signup",
    component: _52610867,
    name: "auth-signup"
  }, {
    path: "/errors/client_unauthorized",
    component: _56f5f4ec,
    name: "errors-client_unauthorized"
  }, {
    path: "/errors/demo_program_not_loaded",
    component: _1f1ec96c,
    name: "errors-demo_program_not_loaded"
  }, {
    path: "/errors/missing_client_id",
    component: _2a0fb27a,
    name: "errors-missing_client_id"
  }, {
    path: "/errors/no_auto_register",
    component: _3bba3361,
    name: "errors-no_auto_register"
  }, {
    path: "/errors/registration_blocked",
    component: _47540012,
    name: "errors-registration_blocked"
  }, {
    path: "/profile/earned_coupons",
    component: _8e879cda,
    name: "profile-earned_coupons"
  }, {
    path: "/profile/pot",
    component: _c819d412,
    name: "profile-pot"
  }, {
    path: "/profile/shoot",
    component: _914b8f7e,
    name: "profile-shoot"
  }, {
    path: "/profile/shop",
    component: _ba7e9f78,
    name: "profile-shop"
  }, {
    path: "/profile/receipts/:receipt_uuid?/review",
    component: _4f3fa6dc,
    name: "profile-receipts-receipt_uuid-review"
  }, {
    path: "/",
    component: _3083fff5,
    name: "index"
  }, {
    path: "/profile/earned_coupons/:identifier",
    component: _8e879cda,
    name: "profile-earned_coupons-_identifier"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
