import { defineSetters } from '../utils/store/utils'

export const state = () => ({
  isIframeOpen: false,
  isLoading: false,
  loadingText: null,
  headerHeight: null,
  footerHeight: null,
  catalogIsModalOpened: false,
  catalogModalReward: null,
})

export const getters = {
  styleTopHeaderHeight: state => ({ top: state.headerHeight }),
}

export const actions = {
  closeCatalogModal ({ commit, dispatch }) {
    dispatch('unblockScroll')
    commit('setCatalogIsModalOpened', false)
    commit('setCatalogModalReward', null)
  },
  openCatalogModal ({ commit, dispatch }, reward) {
    dispatch('blockScroll')
    commit('setCatalogModalReward', reward)
    commit('setCatalogIsModalOpened', true)
  },

  unblockScroll () {
    if (process.server) { return }
    document.body.classList.remove('block-scroll')
  },
  blockScroll () {
    if (process.server) { return }
    document.body.classList.add('block-scroll')
  },
}

export const mutations = defineSetters(state())
