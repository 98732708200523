import resetter from '../utils/store/resetter'

export const state = () => ({
  isConfigLoaded: false,
  partner: {
    externalId: null,
    accessToken: null,
    refreshToken: null,
  },
  fidmarques: {
    accessToken: null,
    refreshToken: null,
  },
  user: null,
  config: null,
  isRegistrationBlocked: null,
})

export const getters = {
  isLogged: (state, getters) => !!state.user || getters.isRegisteredToPartner,
  isRegisteredToPartner: (state) => !!state.partner.accessToken,
  isRegisteredToFidmarques: (state) => !!state.fidmarques.accessToken,
  partnerAccessToken: (state) => state.partner && state.partner.accessToken,
}

export const actions = {
  reset ({ commit }) {
    commit('reset')
  },

  updateNames ({ commit }, payload) {
    commit('setFirstName', payload.firstName)
    commit('setLastName', payload.lastName)
  },

  async setPartnerTokens ({ commit }, tokens) {
    commit('setPartnerTokens', tokens)

    if (process.client && tokens.accessToken) {
      await this.$PE.client.authorizeUserWithToken({
        token: tokens.accessToken,
      })
    }
  },

  async configureUser ({ commit, dispatch }, { user, partner, fidmarques, userExternalId, userExternalEmail }) {
    if (userExternalEmail && fidmarques.isRegistered) {
      commit('credentials/setEmailFromPartner', userExternalEmail, { root: true })
    }

    if (user) {
      commit('setUser', user)
    }

    if (partner) {
      if (partner.externalId) {
        commit('setPartnerExternalId', partner.externalId)
      }
      if (partner.tokens) {
        await dispatch('setPartnerTokens', partner.tokens)
      }
    }

    if (fidmarques && fidmarques.tokens) {
      commit('setFidmarquesTokens', fidmarques.tokens)
    }
  },
}

export const mutations = {
  reset: resetter(state),

  configHasBeenSet (state) {
    state.isConfigLoaded = true
  },

  setPartnerExternalId (state, externalId) {
    state.partner.externalId = externalId
  },

  setPartnerTokens (state, tokens) {
    state.partner.accessToken  = tokens.accessToken
    state.partner.refreshToken = tokens.refreshToken
  },
  setFidmarquesTokens (state, tokens) {
    state.fidmarques.accessToken  = tokens.accessToken
    state.fidmarques.refreshToken = tokens.refreshToken
  },

  setUser (state, user) {
    state.user = user
  },
  setFirstName (state, firstName) {
    state.user.firstName = firstName
  },
  setLastName (state, lastName) {
    state.user.lastName = lastName
  },

  setIsRegistrationBlocked (state, boolean) {
    state.isRegistrationBlocked = boolean
  },
}
