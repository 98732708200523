import sortBy from 'lodash-es/sortBy'

export const hasCard = state => !!state.cardSlot
export const card = state => state.cardSlot.loyalty_card

export const rewardsSortedByPoints =
  (state) => sortBy(state.coupons, 'value')

export const lowestReward =
  (_, getters) => getters.rewardsSortedByPoints[0]

export const selectedReward =
  (state) => state
    .coupons
    .find(r => r.is_next_goal)

export const isSelectedRewardAvailable = (_, getters) => {
  const reward = getters.selectedReward
  return reward ? reward.missing_value === 0 : false
}

export const isSelectedRewardSoonAvailable = (_, getters) => {
  const reward = getters.selectedReward
  if (!reward) { return false }

  const missing = reward.missing_value
  if (!missing) { return false }

  return missing > 0 && missing < 10
}
