import * as types from './mutation-types'

const LIMIT = 10

export function reset ({ commit }) {
  commit(types.RESET)
}

export async function fetch ({ state, commit, dispatch }, $state) {
  try {
    const data = await this.$PE.client.getTimeline({
      limit: LIMIT,
      oldestSeenTimelineOrderedKey: state.oldestSeenTimelineOrderedKey || undefined,
    })
    commit(types.APPEND_TIMELINE, data)
    if (data.messages.length < LIMIT) {
      $state.complete()
    } else {
      $state.loaded()
    }
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'timeline#fetch' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    $state.error()
  }
}

export async function fetchArchives ({ state, commit, dispatch }, $state) {
  if (state.areArchivesLoading) return

  commit(types.SET_ARE_ARCHIVES_LOADING, true)
  try {
    const data = await this.$PE.client.getTimelineArchives({
      limit: LIMIT,
      oldestSeenTimelineOrderedKey: state.archivesOldestSeenTimelineOrderedKey || undefined,
    })
    commit(types.APPEND_ARCHIVES, data)
    if (data.messages.length < LIMIT) {
      $state.complete()
    } else {
      $state.loaded()
    }
    commit(types.SET_ARE_ARCHIVES_LOADED, true)
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'timeline#fetchArchives' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    $state.error()
  } finally {
    commit(types.SET_ARE_ARCHIVES_LOADING, false)
  }
}

export async function toggleArchives ({ state, commit }) {
  if (state.areArchivesOpen) {
    commit(types.SET_ARE_ARCHIVES_OPEN, false)
  } else {
    commit(types.SET_ARE_ARCHIVES_OPEN, true)
  }
}

export function showModalImage ({ commit }, modalImageSrc) {
  commit(types.SET_MODAL_IMAGE_SRC, modalImageSrc)
  commit(types.SET_IS_MODAL_IMAGE_OPEN, true)
}

export function closeModalImage ({ commit }) {
  commit(types.SET_IS_MODAL_IMAGE_OPEN, false)
  commit(types.SET_MODAL_IMAGE_SRC, null)
}

// after cancellation => need to archive message
// export async function updateMessage ({ commit, dispatch }, message) {
//   try {
//     commit(types.UPDATE_MESSAGE, {
//       old: message,
//       new: await this.$PE.client.getTimelineMessage({ message }),
//     })
//   } catch (e) {
//     this.$airbrake.notifyInAsync(e, { locator: 'timeline#updateMessage' }, { store: this })
//     dispatch('addToastGenericError', null, { root: true })
//   }
// }

export async function archiveMessage ({ commit, dispatch }, message) {
  try {
    await this.$PE.client.archiveMessage({ message })
    commit(types.ARCHIVE_MESSAGE, message)
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'timeline#archiveMessage' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
  }
}

export async function cancelReceipt ({ commit, dispatch }, receiptUuid) {
  try {
    await this.$PE.client.cancelReceipt({ receiptUuid })
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'timeline#cancelReceipt' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
  }
}
