import * as types from './mutation-types'

function unwrapPotRefund (potRefunds) {
  return potRefunds.map(pr => pr.pot_refund)
}
function unwrapOtherAppPotRefund (potRefunds) {
  return potRefunds.map(pr => pr.other_app_pot_refund)
}

export function reset ({ commit }) {
  commit(types.RESET)
}

export async function fetch ({ commit, dispatch }) {
  commit(types.RESET)
  commit(types.SET_IS_POT_LOADING, true)

  try {
    await dispatch('setAll', await this.$PE.client.getLoyaltyPot())
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'Pot#fetch' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
  } finally {
    commit(types.SET_IS_POT_LOADING, false)
  }
}

export function setAll ({ commit }, payload) {
  commit(types.SET_POT, payload.pot)
  commit(types.SET_POT_REFUNDS, unwrapPotRefund(payload.pot_refunds))
  commit(types.SET_OTHER_APP_POT_REFUNDS, unwrapOtherAppPotRefund(payload.other_app_pot_refunds))

  if (payload.previous_transfer_payment) {
    commit(types.ADD_TRANSFER_PAYMENT, payload.previous_transfer_payment)
    commit(types.SET_PREVIOUS_TRANSFER_PAYMENT_ID, payload.previous_transfer_payment.id)
  }
}

export async function fetchTransferPayment ({ state, commit }, $state) {
  const id = state.previousTransferPaymentId
  if (!id) { return $state.complete() }

  try {
    const res = await this.$PE.client.getLoyaltyPotRefunds({ transferPaymentId: id })

    commit(types.ATTACH_ALL_REFUNDS, {
      id,
      potRefunds: unwrapPotRefund(res.pot_refunds),
      otherAppPotRefunds: unwrapOtherAppPotRefund(res.other_app_pot_refunds),
    })

    if (res.previous_transfer_payment) {
      commit(types.ADD_TRANSFER_PAYMENT, res.previous_transfer_payment)
      commit(types.SET_PREVIOUS_TRANSFER_PAYMENT_ID, res.previous_transfer_payment.id)
      $state.loaded()
    } else {
      commit(types.SET_PREVIOUS_TRANSFER_PAYMENT_ID, null)
      $state.complete()
    }
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'Pot#fetchTransferPayment' }, { store: this })
    $state.error()
  }
}

export async function createTransferPayment ({ commit, dispatch }) {
  commit(types.SET_IS_CREATING_TRANSFER_PAYMENT, true)

  try {
    await this.$PE.client.createTransferPayment()
    dispatch('fetch')
    dispatch('addToast', {
      text: 'Transfert demandé avec succès.',
      type: 'success',
      dismissAfter: 4000,
    }, { root: true })
  } catch (e) {
    if (e instanceof this.$PE.Error) {
      switch (e.code) {
        case this.$PE.Error.Codes.MISSING_PAYMENT_INFORMATION:
          dispatch('addToast', {
            text: 'Merci de rentrer vos coordonnées bancaires.',
            type: 'danger',
            dismissAfter: 4000,
          }, { root: true })
          break
        case this.$PE.Error.Codes.EMPTY_POT:
          dispatch('addToast', {
            text: 'Désolé, votre cagnotte est vide. Vous ne pouvez pas demander de virement.',
            type: 'danger',
            dismissAfter: 4000,
          }, { root: true })
          break
        default:
          this.$airbrake.notifyFromKit(e, { locator: 'Pot#createTransferPayment#1' }, { store: this })
          dispatch('addToastGenericError', null, { root: true })
      }
    } else {
      this.$airbrake.notifyInAsync(e, { locator: 'Pot#createTransferPayment#2' }, { store: this })
      dispatch('addToastGenericError', null, { root: true })
    }
  } finally {
    commit(types.SET_IS_CREATING_TRANSFER_PAYMENT, false)
  }
}
