export const RESET = 'RESET'

export const SET_IS_SHOP_LOADING = 'SET_IS_SHOP_LOADING'
export const SET_IS_SHOP_LOADED = 'SET_IS_SHOP_LOADED'

export const SET_CATALOG = 'SET_CATALOG'
export const SET_COUPONS = 'SET_COUPONS'
export const UPDATE_COUPON_KEY = 'UPDATE_COUPON_KEY'
export const UPDATE_COUPON_VALUES = 'UPDATE_COUPON_VALUES'
export const UPDATE_COUPON_SEPARATORS = 'UPDATE_COUPON_SEPARATORS'
export const TOGGLE_COUPON_NEXT_GOAL = 'TOGGLE_COUPON_NEXT_GOAL'

export const SELECT_COUPON = 'SELECT_COUPON'
export const SET_IS_COUPON_MODAL_OPEN = 'SET_IS_COUPON_MODAL_OPEN'
