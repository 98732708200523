import Vue from 'vue'

import resetter from '../../utils/store/resetter'
import createInitialState from './state'

import * as types from './mutation-types'

export default {
  [types.RESET]: resetter(createInitialState),

  [types.SET_RECEIPT] (state, receipt) {
    state.receipt = receipt
  },

  [types.SET_IS_REVIEW_LOADED] (state, boolean) {
    state.isReviewLoaded = boolean
  },
  [types.SET_IS_REVIEW_LOADING] (state, boolean) {
    state.isReviewLoading = boolean
  },

  [types.SET_PURCHASES_MODE] (state, mode) {
    state.purchasesMode = mode
  },

  [types.SET_SELECTED_PURCHASE] (state, purchase) {
    state.selectedPurchase = purchase
  },
  [types.OPEN_MODAL] (state, modalName) {
    state.openModalName = modalName
  },
  [types.UPDATE_PURCHASES] (state, updatedPurchases) {
    updatedPurchases.forEach(updatedPurchase => {
      const index = state.receipt.purchases.findIndex(p =>
        (p.purchase_id === updatedPurchase.purchase_id)
      )
      if (index === -1) {
        throw new Error('purchase not found')
      } else {
        Vue.set(state.receipt.purchases, index, updatedPurchase)
      }
    })
  },

  [types.SET_IS_CLAIMING_ON_PURCHASE] (state, boolean) {
    state.isClaimingOnPurchase = boolean
  },
  [types.SET_IS_CLAIMING_ON_RECEIPT] (state, boolean) {
    state.isClaimingOnReceipt = boolean
  },
  [types.SET_IS_VALIDATING] (state, boolean) {
    state.isValidating = boolean
  },
  [types.SET_IS_REPORTING_ON_RECEIPT] (state, boolean) {
    state.isReportingOnReceipt = boolean
  },

  [types.UPSERT_ORPHAN_CLAIM] (state, claim) {
    const idx = state.receipt.claim_without_purchases.findIndex(c => c.id === claim.id)
    if (idx === -1) {
      state.receipt.claim_without_purchases.push(claim)
    } else {
      Vue.set(state.receipt.claim_without_purchases, idx, claim)
    }
  },

  [types.SET_IS_LOADING_COUPONS] (state, boolean) {
    state.isLoadingCoupons = boolean
  },
  [types.SET_AVAILABLE_COUPONS] (state, coupons) {
    state.availableCoupons = coupons
  },
  [types.RESET_COUPONS] (state) {
    state.availableCoupons = []
  },
}
