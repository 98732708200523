import * as types from './mutation-types'

import { CONTEXT_BUY, CONTEXT_CANCEL } from './mutations'

export function reset ({ commit }) {
  commit(types.RESET)
}

export async function fetch ({ commit, dispatch, rootState }) {
  // commit(types.RESET)
  commit(types.SET_IS_SHOP_LOADING, true)

  try {
    const catalogs = await this.$PE.client.getCatalogs()
    const catalog = findCorrectCatalog(catalogs, this, rootState)
    commit(types.SET_CATALOG, catalog)
    commit(types.SET_COUPONS, catalog.coupons)
    dispatch('server/setPoints', catalog.loyalty_card.available_points, { root: true })
    commit(types.SET_IS_SHOP_LOADED, true)
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'shop#fetch' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
  } finally {
    commit(types.SET_IS_SHOP_LOADING, false)
  }
}

// TODO: after some time without error
// just use `catalogs[0]` instead
function findCorrectCatalog (catalogs, store, rootState) {
  let catalog
  switch (catalogs.length) {
    case 0:
      return // will raise
    case 1:
      catalog = catalogs[0]
      if (catalog.owner_type !== 'LoyaltyCard' || catalog.loyalty_card.name !== rootState.server.programName) {
        store.$airbrake.notifyInAsync(new Error('catalog mismatch'), { locator: 'findCorrectCatalog 1' }, { store })
      }
      return catalog
    default:
      store.$airbrake.notifyInAsync(new Error('multiple catalogs'), { locator: 'findCorrectCatalog 2' }, { store })
      catalog = catalogs.find(c => (
        c.owner_type === 'LoyaltyCard' &&
        c.loyalty_card.name === rootState.server.programName
      ))
      if (!catalog) {
        store.$airbrake.notifyInAsync(new Error('no catalog'), { locator: 'findCorrectCatalog 3' }, { store })
      }
      return catalog
  }
}

export async function buyCoupon ({ commit, dispatch, rootState }, coupon) {
  commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isBuying', value: true })

  try {
    await this.$PE.client.buyCoupon({ coupon })
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'shop#buyCoupon' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isBuying', value: false })
    return
  }

  dispatch('server/decrementPoints', coupon.value, { root: true })
  commit(types.UPDATE_COUPON_VALUES, {
    availablePoints: rootState.server.points,
    context: CONTEXT_BUY,
  })
  commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isBuying', value: false })
  commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isCancellable', value: true })
}

export async function cancelCoupon ({ commit, dispatch, rootState }, coupon) {
  commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isCancelling', value: true })

  try {
    await this.$PE.client.cancelCoupon({ coupon })
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'shop#cancelCoupon' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
    commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isCancelling', value: false })
    return
  }

  dispatch('server/incrementPoints', coupon.value, { root: true })
  commit(types.UPDATE_COUPON_VALUES, {
    availablePoints: rootState.server.points,
    context: CONTEXT_CANCEL,
  })
  commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isCancelling', value: false })
  commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isCancellable', value: false })
}

export async function toggleGoal ({ state, commit, dispatch }, coupon) {
  commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isGoalToggling', value: true })

  try {
    if (coupon.is_next_goal) {
      await this.$PE.client.cancelNextGoal({ coupon })
    } else {
      await this.$PE.client.selectNextGoal({ coupon })
    }
    // performance : MAJ done in client instead of via HTTP call
    // await dispatch('reloadCatalog')
    commit(types.TOGGLE_COUPON_NEXT_GOAL, coupon)
    commit(types.UPDATE_COUPON_SEPARATORS)
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'shop#toggleGoal' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
  }

  commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isGoalToggling', value: false })
}

export function timeoutCancelCoupon ({ commit, dispatch }, coupon) {
  commit(types.UPDATE_COUPON_KEY, { coupon, key: 'isCancellable', value: false })
}

export async function reloadCatalog ({ state, commit, dispatch, rootState }) {
  try {
    const coupons = await this.$PE.client.getCardCatalog({ card: state.catalog.loyalty_card })
    commit(types.SET_COUPONS, coupons)
  } catch (e) {
    this.$airbrake.notifyInAsync(e, { locator: 'shop#reloadCatalog' }, { store: this })
    dispatch('addToastGenericError', null, { root: true })
  }
}

export function showDetails ({ commit }, coupon) {
  commit(types.SELECT_COUPON, coupon)
  commit(types.SET_IS_COUPON_MODAL_OPEN, true)
}

export function closeDetails ({ commit }) {
  commit(types.SELECT_COUPON, null)
  commit(types.SET_IS_COUPON_MODAL_OPEN, false)
}
