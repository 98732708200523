import resetter from '../utils/store/resetter'

export const state = () => ({
  emailFromPartner: null,
  email: null,
  password: null,
  passwordConfirmation: null,
  hasActivationCode: false,
  activationCode: '',
  areTermsAccepted: false,
  errors: [],
})

export const actions = {
  reset ({ commit }) {
    commit('reset')
  },
}

export const mutations = {
  reset: resetter(state),

  setEmailFromPartner (state, email) {
    state.emailFromPartner = email
    state.email = email
  },

  setEmail (state, email) {
    state.email = email
  },
  setPassword (state, password) {
    state.password = password
  },
  setPasswordConfirmation (state, passwordConfirmation) {
    state.passwordConfirmation = passwordConfirmation
  },
  setHasActivationCode (state, boolean) {
    state.hasActivationCode = boolean
  },
  setActivationCode (state, activationCode) {
    state.activationCode = activationCode
  },
  setAreTermsAccepted (state, boolean) {
    state.areTermsAccepted = boolean
  },

  clearErrors: state => { state.errors = [] },
  addError:  (state, error)  => { state.errors.push(error) },
  setErrors: (state, errors) => { state.errors = errors },
}

export const getters = {
  hasErrors: state => state.errors.length !== 0,
}
