<template>
  <div class="modal-loader">
    <Modal
      :container-style="{
        width: '200px',
        margin: 'auto',
      }"
    >
      <div slot="header" />
      <LoaderPacman slot="body" />
      <div slot="footer" class="modal-loader__footer">
        {{ loadingText }}
      </div>
    </Modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '~/components/elements/Modal'
import LoaderPacman from '~/components/loaders/Pacman'
export default {
  components: { Modal, LoaderPacman },
  computed: {
    ...mapState('layout', ['loadingText']),
  },
}
</script>

<style scoped>
.modal-loader__footer {
  text-align: center;
  font-weight: 700;
  padding: 0 1rem 0.5rem 1rem;
}
</style>
