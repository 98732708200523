export const PURCHASE_MODE_ALL = 'all'
export const PURCHASE_MODE_POINTS = 'points'
export const PURCHASE_MODE_REFUNDS = 'refunds'
export const PURCHASE_MODE_COLLECTOR_PIECES = 'collector-pieces'
export const PURCHASE_MODE_COLLECTOR_JOKERS = 'collector-jokers'

export const MODAL_NAME_IMAGE = 'image'
export const MODAL_NAME_SCAN = 'scan'
export const MODAL_NAME_TRUSTING = 'trusting'

export const MODAL_NAME_REPORT = 'report'
export const MODAL_NAME_HOW_TO = 'how-to'
export const MODAL_NAME_MISSING_LINES = 'missing-lines'
export const MODAL_NAME_ZENDESK = 'zendesk'

export const MODAL_NAME_REPORT_REFUND = 'report-refund'
export const MODAL_NAME_SELECT_REFUND = 'select-refund'

export default () => ({
  receipt: null,
  isReviewLoaded: false,
  isReviewLoading: false,

  selectedPurchase: null,
  openModalName: null,
  MODALS: {
    IMAGE: MODAL_NAME_IMAGE,
    SCAN: MODAL_NAME_SCAN,
    TRUSTING: MODAL_NAME_TRUSTING,
    REPORT: MODAL_NAME_REPORT,
    HOW_TO: MODAL_NAME_HOW_TO,
    MISSING_LINES: MODAL_NAME_MISSING_LINES,
    ZENDESK: MODAL_NAME_ZENDESK,
    REPORT_REFUND: MODAL_NAME_REPORT_REFUND,
    SELECT_REFUND: MODAL_NAME_SELECT_REFUND,
  },

  isClaimingOnPurchase: false,
  isClaimingOnReceipt: false,
  isReportingOnReceipt: false,

  purchasesMode: PURCHASE_MODE_ALL,
  PURCHASE_MODES: {
    ALL: PURCHASE_MODE_ALL,
    POINTS: PURCHASE_MODE_POINTS,
    REFUNDS: PURCHASE_MODE_REFUNDS,
    COLLECTOR_PIECES: PURCHASE_MODE_COLLECTOR_PIECES,
    COLLECTOR_JOKERS: PURCHASE_MODE_COLLECTOR_JOKERS, // not used
  },

  isLoadingCoupons: false,
  availableCoupons: [],
})
