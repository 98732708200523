<template>
  <div class="app" :class="classList" :style="style">
    <nuxt />
    <LoaderModal v-if="isLoading" />
    <toast html position="s" namespace="toast" />

    <ModalReceiptImage
      v-if="isModalImageOpen"
      :src="modalImageSrc"
      @close="closeModalImage"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { Toast } from 'vuex-toast'

import LoaderModal from '../components/modals/Loader'
import ModalReceiptImage from '../components/shared/ModalReceiptImage'

import attachTrackers from '../utils/attachTrackers'

export default {
  components: { LoaderModal, Toast, ModalReceiptImage },
  head ($vm) {
    return attachTrackers($vm.trackers)
  },
  computed: {
    ...mapState('trackers', ['trackers']),

    ...mapState('layout', ['isLoading', 'headerHeight', 'footerHeight']),
    ...mapState('timeline', ['isModalImageOpen', 'modalImageSrc']),

    classList () {
      const list = []
      if (this.$route.name) { list.push('app--' + this.$route.name) }
      return list
    },
    style () {
      return {
        paddingTop:    this.headerHeight,
        paddingBottom: this.footerHeight,
      }
    },
  },
  methods: {
    ...mapActions('timeline', ['closeModalImage']),
  },
}
</script>

<style src="assets/css/knacss.css"></style>
<style src="assets/css/vuex-toast.scss" lang="scss"></style>
<style src="assets/css/fonts.scss" lang="scss"></style>

<style lang="scss">
@import "~assets/css/variables";

html {
  // background-color: #faf5f0;
}

body {
  font-family: $font-family-system;

  margin: 0;
  @extend .default-background
}
.default-background {
  background-color: #f8f8f8;
}
/* stopping background scroll when modal is open */
body.block-scroll {
  overflow: hidden;
}
.app {
  height: 100%;
}
.section {
  font-size: 1.3rem;
}
.router-link-active {
  color: red;
}

.img-responsive {
  max-width: 100%;
  max-height: 100%;
}
/* overiding primaryColor as inline attribute */
.a-hover-black:hover {
  color: black !important
}

.app--profile-earned_coupons {
  // background-color: $background-color-v3;
}
</style>

<style lang="scss">
@import "~assets/css/mixins";

.font-family__open-sans {
  @include font-family__open-sans
}
.font-family__oswald {
  @include font-family__oswald
}
</style>
