import sumBy from 'lodash-es/sumBy'

// import { formatDate } from '../../../plugins/global-filters'

export const paidAt = state =>
  state.receipt &&
  state.receipt.paid_at &&
  new Date(state.receipt.paid_at)

// export const pageTitle = (_, getters) => getters.paidAt ? 'Revue de ticket - ' + formatDate(getters.paidAt, 'DD/MM/YYYY') : 'Revue de ticket'

export const receiptImage = state => state.receipt && state.receipt.image_url

// 1. loyalty_reward_usages

export const refunds = (state) =>
  state.receipt ? state.receipt.loyalty_reward_usages : []

// 1.1. receipt review

export const creditedRefunds = (_, getters) =>
  getters.refunds.filter(lru => lru.status === 'CREDITED')

export const creditedRefundsCount = (_, getters) => getters.creditedRefunds.length
export const hasCreditedRefunds = (_, getters) => getters.creditedRefundsCount !== 0

// 1.2. refunds with full details

export const refundsFromPartner = (_, getters) => getters.refunds

export const refundsFromPartnerCount = (_, getters) => getters.refundsFromPartner.length
export const hasRefundsFromPartner = (_, getters) => getters.refundsFromPartnerCount !== 0

// 1.3. refunds grouped by status

export const refundsFromOtherBrandsByStatus = (state) =>
  state.receipt.other_brands_recap
    ? state.receipt.other_brands_recap.offer_usages_by_status
    : []

export const refundsFromOtherBrandsCount = (_, getters) =>
  sumBy(Object.values(getters.refundsFromOtherBrandsByStatus), 'count')

export const hasRefundsFromOtherBrands = (_, getters) => getters.refundsFromOtherBrandsCount !== 0

// 2. claims

export const claimWithoutPurchases = (state) =>
  state.receipt ? state.receipt.claim_without_purchases : []

export const claimWithoutPurchasesCount = (_, getters) => getters.claimWithoutPurchases.length
export const hasOrphanClaims = (_, getters) => getters.claimWithoutPurchasesCount !== 0

// 3. purchases

export const purchases = (state) =>
  state.receipt ? state.receipt.purchases : []

export const purchasesWithPoints = (_, getters) =>
  getters.purchases.filter(p => p.loyalty_points)

export const purchasesWithRefunds = (_, getters) =>
  getters.purchases.filter(p => p.refund_status)

export const purchasesWithCollectorPieces = (_, getters) =>
  getters.purchases.filter(p => p.collector_item)

export const purchasesWithCollectorJokers = (_, getters) =>
  getters.purchases.filter(p => p.collector_joker)

export const purchasesDisplayed = (state, getters) => {
  switch (state.purchasesMode) {
    case state.PURCHASE_MODES.ALL:
      return getters.purchases
    case state.PURCHASE_MODES.POINTS:
      return getters.purchasesWithPoints
    case state.PURCHASE_MODES.REFUNDS:
      return getters.purchasesWithRefunds
    case state.PURCHASE_MODES.COLLECTOR_PIECES:
      return getters.purchasesWithCollectorPieces
    case state.PURCHASE_MODES.COLLECTOR_JOKERS:
      return getters.purchasesWithCollectorJokers
  }
  // should be impossible
  return getters.purchases
}

// 4. points

export const points = (_, getters) => getters.purchasesWithPoints.map(p => p.loyalty_points)

export const pointsCount = (_, getters) => sumBy(getters.points, 'count')
export const hasPoints = (_, getters) => getters.pointsCount !== 0

// 5. collectors

export const collectorPieces = (_, getters) =>
  getters.purchasesWithCollectorPieces.map(p => p.collector_item)

export const collectorPiecesCount = (_, getters) => getters.collectorPieces.length
export const hasCollectorPieces = (_, getters) => getters.collectorPiecesCount !== 0
