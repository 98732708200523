export default () => ({
  hasArchives: false,
  hasBenefitsSummary: false,

  messages: [],
  infiniteIdentifier: +new Date(),
  oldestSeenTimelineOrderedKey: null,

  archivedMessages: [],
  archivesInfiniteIdentifier: +new Date(),
  archivesOldestSeenTimelineOrderedKey: null,

  isTimelineLoading: true,
  isTimelineLoaded: false,
  areArchivesLoading: false,
  areArchivesLoaded: false,
  areArchivesOpen: false,

  isModalImageOpen: false,
  modalImageSrc: null,

  isValidating: false,
})
