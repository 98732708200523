export default ({ app, store }) => {
  if (process.server) { return }

  const id =
    store.state.trackers.trackers &&
    store.state.trackers.trackers.purcheaseGaId
  if (!id) {
    window.ga = function () {}
    return
  }

  /* eslint-disable */
  ;(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');
  /* eslint-disable */

  ga('create', id, 'auto')
  app.router.afterEach((to, from) => {
    ga('set', 'page', to.fullPath)
    ga('send', 'pageview')
  })
  store.watch(
    // (state, getters) => state.thingToObserve
    (state) => state.layout.isIframeOpen,
    // (newValue, oldValue) => { doSomething() }
    (val) => {
      if (val) {
        ga('send', 'event', 'widget', 'open')
      } else {
        ga('send', 'event', 'widget', 'close')
      }
    }
  )
}
