import { isArray, mergeWith, toPairs } from 'lodash-es'

import * as trackerInjecters from '../utils/trackers'

function customizer (objValue, srcValue) {
  if (isArray(objValue)) {
    return objValue.concat(srcValue)
  }
}

// https://developers.google.com/tag-manager/devguide?hl=en#multiple-containers
// (we can rename GTM data layers, but they should all be the same name)

export default (trackers) => {
  const results = []
  toPairs(trackers).map(([key, value]) => {
    if (trackerInjecters[key]) {
      results.push(trackerInjecters[key](value))
    }
  })

  return mergeWith({}, ...results, customizer)
}
