export const RESET = 'RESET'

export const SET_IS_POT_LOADING = 'SET_IS_POT_LOADING'
export const SET_IS_CREATING_TRANSFER_PAYMENT = 'SET_IS_CREATING_TRANSFER_PAYMENT'

export const SET_POT = 'SET_POT'
export const SET_POT_REFUNDS = 'SET_POT_REFUNDS'
export const SET_OTHER_APP_POT_REFUNDS = 'SET_OTHER_APP_POT_REFUNDS'

export const ATTACH_ALL_REFUNDS = 'ATTACH_ALL_REFUNDS'
export const ADD_TRANSFER_PAYMENT = 'ADD_TRANSFER_PAYMENT'
export const SET_PREVIOUS_TRANSFER_PAYMENT_ID = 'SET_PREVIOUS_TRANSFER_PAYMENT_ID'
