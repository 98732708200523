import AirbrakeClient from 'airbrake-js'
import pick from 'lodash-es/pick'

import { Response } from 'cross-fetch'

import env from './env'
import isDev from './is-dev'

const projectId  = process.env.FIDMARQUES_WEB_WIDGET_AIRBRAKE_PROJECT_ID  || process.env.AIRBRAKE_PROJECT_ID
const projectKey = process.env.FIDMARQUES_WEB_WIDGET_AIRBRAKE_PROJECT_KEY || process.env.AIRBRAKE_PROJECT_KEY

const airbrake = new AirbrakeClient({
  projectId,
  projectKey,
  environment: env,
})

const logger = console.error || console.log

// eslint-disable-next-line no-new-func
const isNodeTest = new Function('try { return this === global; } catch (e) { return false; }')
const isNode = isNodeTest()

function logNotice (notice) {
  logger('AIRBRAKE : ' + notice.errors[0].message)
  logger(notice)
}

airbrake.addFilter((notice) => {
  if (process.env.LOCAL_DEBUG) {
    logNotice(notice)
    return null
  }

  if (!isDev) { return notice }
  if (isNode) { return notice }
  if (process.server) { return notice }

  logNotice(notice)

  return null
})

airbrake.addFilter((notice) => {
  notice.context.API_ENV  = process.env.API_ENV
  notice.context.NODE_ENV = process.env.NODE_ENV
  notice.context.VUE_ENV  = process.env.VUE_ENV
  return notice
})

const JQUERY_MESSAGES = [
  '$ is not defined', // chrome, firefox
  "Can't find variable: $", // safari
]
airbrake.addFilter((notice) => {
  if (!airbrake.disableJqueryErrors) return notice
  if (notice.errors[0].type !== 'ReferenceError') return notice
  if (!JQUERY_MESSAGES.includes(notice.errors[0].message)) return notice

  return null
})

function sanitizeNuxtContext (context) {
  // ommited : ['app', 'store', 'error', 'req', 'res', 'redirect', 'beforeNuxtRender', 'next'] + keys beginning by $
  return {
    ...pick(context, ['isStatic', 'isDev', 'isHMR', 'route', 'params', 'query']),
    state: context.store && context.store.state,
  }
}

airbrake.notifyFromComponent = function (error, params, context = {}) {
  this.notify({
    error,
    params,
    context: {
      nuxt: sanitizeNuxtContext({ ...context, store: context.vm && context.vm.$store }),
    },
  })
}

airbrake.notifyFromKit = function (error, params, context = {}) {
  this.notify({
    error,
    params: {
      ...params,
      source: 'purchease-kit-web',
      errorCode: error.code,
      invalidParameter: error.invalidParameter,
      invalidReason: error.invalidReason,
    },
    context: {
      nuxt: sanitizeNuxtContext(context),
    },
  })
}

airbrake.notifyWithNuxtContext = function (error, params, context = {}) {
  this.notify({
    error,
    params,
    context: {
      nuxt: sanitizeNuxtContext(context),
    },
  })
}

airbrake.notifyWithFetchResponse = async function (ourError, response, context = {}) {
  let data
  try {
    data = await response.json()
  } catch (e) {}

  this.notify({
    error: ourError,
    params: {
      fetch: {
        request: response.request,
        response,
        responseData: data,
      },
      express: {
        req: context.req && {
          url: context.req.originalUrl,
          method: context.req.method,
          params: context.req.params,
        },
      },
    },
    context: {
      zzz: context,
    },
  })
}

airbrake.notifyInAsync = function (error, params, context = {}) {
  if (error.isAxiosError) {
    this.notifyWithAxiosError(error, params, context)
  } else {
    this.notify({
      error,
      params: {
        errorObject: error,
        ...params,
      },
      context: {
        nuxt: sanitizeNuxtContext(context),
      },
    })
  }
}

airbrake.notifyWithAxiosError = function (error, params, context = {}) {
  let data
  try {
    data = JSON.parse(error.config.data)
  } catch (e) {}

  this.notify({
    error,
    params: {
      axios: {
        request: {
          url: error.config && error.config.url,
          method: error.config && error.config.method,
          data,
          queryString: error.config && error.config.params,
        },
        response: pick(error.response, ['status', 'data']),
      },
      ...params,
    },
    context: {
      zzz: {
        error,
        params,
        context,
        state: context && context.vm && context.vm.$store && context.vm.$store.state,
      },
    },
  })
}

airbrake.notifyFromExpress = function (error, req, _res, context = {}) {
  const params = {
    source: 'express',
    request: {
      method: req.method,
      url: req.originalUrl,
      data: req.body,
      queryString: error.response && error.response.config && error.response.config.params,
      // headers: req.headers,
    },
    response: error.response && pick(error.response, ['statusCode', 'headersSent']),
  }

  this.notify({
    error,
    params,
    session: req.session,
    context,
  })
}

airbrake.notifyFromHipchat = function (error, res) {
  if (!error) { return }
  this.notify({
    error,
    params: {
      source: 'hipchat',
      result: res,
    },
  })
}
airbrake.notifyFromHipchatBinded = airbrake.notifyFromHipchat.bind(airbrake)

airbrake.notifyFromServer = function (error, ourError, req, res) {
  if (error instanceof Response) {
    airbrake.notifyWithFetchResponse(ourError, error, { req, res })
  } else if (error.isAxiosError) {
    airbrake.notifyWithAxiosError(error, { ourError }, { req, res })
  } else {
    airbrake.notifyFromExpress(error, req, res, { ourError })
  }
}

const plugin = {
  install (Vue, options) {
    Vue.config.errorHandler = function (err, vm, info) {
      airbrake.notify({
        error: err,
        params: {
          library: 'vue',
          info,
          componentData: vm && vm.$data,
        },
        context: {
          state: vm && vm.$store && vm.$store.state,
          zzz: {
            err,
            vm,
            info,
          },
        },
      })
    }

    Vue.prototype.$airbrake = Vue.airbrake = airbrake
  },
}

const errorMiddleware = (err, req, res, next) => {
  airbrake.notifyFromServer(err, new Error('error from error middleware'), req, res)

  next(err)
}

export {
  airbrake as client,
  plugin,
  errorMiddleware,
}

export default airbrake
