<template>
  <div :style="style">
    <nuxt />
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState('layout', ['headerHeight', 'footerHeight']),

    classList () {
      const list = []
      if (this.$route.name) { list.push('app--' + this.$route.name) }
      return list
    },
    style () {
      return {
        paddingTop:    this.headerHeight,
        paddingBottom: this.footerHeight,
      }
    },
  },
}
</script>
