import Vue from 'vue'

import { pluralize } from '../../plugins/global-filters'

import resetter from '../../utils/store/resetter'
import createInitialState from './state'

import * as types from './mutation-types'

export const CONTEXT_BUY = 'buy'
export const CONTEXT_CANCEL = 'cancel'

function upsertedCouponSeparators (coupons) {
  const hydratedCoupons = coupons.filter(c => (c.componentTag === 'ListCouponItem'))

  const indexTarget = hydratedCoupons.findIndex(coupon => coupon.is_next_goal)
  const index = hydratedCoupons.findIndex((coupon, idx) =>
    coupon.isBuyable &&
    hydratedCoupons[idx + 1] &&
    !hydratedCoupons[idx + 1].isBuyable
  )
  if (index !== -1) {
    hydratedCoupons.splice(index + 1, 0, {
      identifier: 'separator',
      componentTag: 'ListSeparator',
      text: (
        (indexTarget !== -1) && indexTarget > index
          ? 'Continuez à cumuler pour débloquer votre choix !'
          : "Pour débloquer d'autres cadeaux, continuez vos achats !"
      ),
      textUppercase: true,
    })
  }

  return hydratedCoupons
}

function hydratedCoupons (coupons) {
  const hydratedCoupons = coupons.map(coupon => ({
    ...coupon,
    componentTag: 'ListCouponItem',
    isBuyable: coupon.missing_value === 0,
    isBuying: false,
    isCancellable: false,
    isCancelling: false,
    isGoalToggling: false,
  }))
  return upsertedCouponSeparators(hydratedCoupons)
}

function getNewValue (coupon, context) {
  switch (context) {
    case CONTEXT_BUY:
      return coupon.is_showable_as_a_discount
        ? coupon.progression_next_value
        : coupon.value
    case CONTEXT_CANCEL:
      return coupon.value
    default:
      // TODO: alert on slack (should not happen)
      return coupon.value
  }
}

export default {
  [types.RESET]: resetter(createInitialState),

  [types.SET_IS_SHOP_LOADING] (state, boolean) {
    state.isShopLoading = boolean
  },
  [types.SET_IS_SHOP_LOADED] (state, boolean) {
    state.isShopLoaded = boolean
  },

  [types.SET_CATALOG] (state, catalog) {
    state.catalog = catalog
  },
  [types.SET_COUPONS] (state, coupons) {
    state.hydratedCoupons = hydratedCoupons(coupons)
  },

  [types.UPDATE_COUPON_KEY] (state, { coupon, key, value }) {
    Vue.set(coupon, key, value)
  },
  [types.UPDATE_COUPON_VALUES] (state, { availablePoints, context }) {
    state.hydratedCoupons.forEach(coupon => {
      const newValue = getNewValue(coupon, context)
      const isBuyable = newValue <= availablePoints
      const missingValue = isBuyable ? 0 : newValue - availablePoints
      const formattedMissingValue = isBuyable
        ? 'Débloqué!'
        : pluralize(missingValue, `${missingValue} point manquant`, `${missingValue} points manquants`)

      Vue.set(coupon, 'isBuyable', isBuyable)
      Vue.set(coupon, 'missing_value', missingValue)
      Vue.set(coupon, 'formatted_missing_value', formattedMissingValue)
    })
    state.hydratedCoupons = upsertedCouponSeparators(state.hydratedCoupons)
  },
  [types.UPDATE_COUPON_SEPARATORS] (state) {
    state.hydratedCoupons = upsertedCouponSeparators(state.hydratedCoupons)
  },
  [types.TOGGLE_COUPON_NEXT_GOAL] (state, coupon) {
    state.hydratedCoupons.forEach(c => {
      if (c.identifier === coupon.identifier) {
        Vue.set(c, 'is_next_goal', !c.is_next_goal)
      } else {
        Vue.set(c, 'is_next_goal', false)
      }
    })
  },

  [types.SET_IS_COUPON_MODAL_OPEN] (state, boolean) {
    state.isCouponModalOpen = boolean
  },
  [types.SELECT_COUPON] (state, coupon) {
    state.selectedCoupon = coupon
  },
}
