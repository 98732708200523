export default function logout ({
  $axios,
  $route,
  $airbrake,
  $store,
  $router,

  locator,
}) {
  $axios.delete('/api/logout', { data: { clientId: $route.query.clientId } })
    .catch(error => { $airbrake.notifyInAsync(error, { locator }, { store: $store }) })

  $store.dispatch('logout')
  if ($router) $router.push('/')
}
