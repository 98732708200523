export const RESET = 'RESET'

export const SET_RECEIPT = 'SET_RECEIPT'

export const SET_IS_REVIEW_LOADED = 'SET_IS_REVIEW_LOADED'
export const SET_IS_REVIEW_LOADING = 'SET_IS_REVIEW_LOADING'

export const SET_PURCHASES_MODE = 'SET_PURCHASES_MODE'

export const SET_SELECTED_PURCHASE = 'SET_SELECTED_PURCHASE'
export const OPEN_MODAL = 'OPEN_MODAL'
export const UPDATE_PURCHASES = 'UPDATE_PURCHASES'

export const SET_IS_CLAIMING_ON_PURCHASE = 'SET_IS_CLAIMING_ON_PURCHASE'
export const SET_IS_CLAIMING_ON_RECEIPT = 'SET_IS_CLAIMING_ON_RECEIPT'
export const SET_IS_VALIDATING = 'SET_IS_VALIDATING'
export const SET_IS_REPORTING_ON_RECEIPT = 'SET_IS_REPORTING_ON_RECEIPT'

export const UPSERT_ORPHAN_CLAIM = 'UPSERT_ORPHAN_CLAIM'

export const SET_IS_LOADING_COUPONS = 'SET_IS_LOADING_COUPONS'
export const SET_AVAILABLE_COUPONS = 'SET_AVAILABLE_COUPONS'
export const RESET_COUPONS = 'RESET_COUPONS'
