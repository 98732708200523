import Vue from 'vue'
import { client, plugin } from '../utils/airbrake'
Vue.use(plugin)

export default (ctx, inject) => {
  client.disableJqueryErrors = !!(process.client && ctx.store.state.disableJqueryErrors)

  ctx.$airbrake = client
  inject('airbrake', client)
}
