<template>
  <Modal
    :container-style="{
      position: 'relative',
      maxHeight: 'calc(100vh - 1rem)',
      overflow: 'auto',
    }"
    @close="$emit('close')"
  >
    <div slot="header">
      <a class="button-close" @click.prevent.stop="$emit('close')">
        <img src="~assets/images/icons/icon-close-inverse/icon-close-inverse.png">
      </a>
    </div>
    <div slot="body">
      <img :src="src">
    </div>
  </Modal>
</template>

<script>
import { mapState } from 'vuex'
import Modal from '../elements/Modal'
export default {
  components: { Modal },
  props: {
    src: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('layout', ['footerHeight']),

    headerHeight () {
      return '5rem'
    },
  },
}
</script>

<style>
.button-close {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border-radius: 50%;
  padding: 5px;
  display: flex;
}
</style>
