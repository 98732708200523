export default function (ctx) {
  const { req, store, redirect } = ctx
  if (!store.getters['auth/isRegisteredToPartner']) {
    if (process.server) {
      return redirect('/' + req.urlParsed.search)
    } else {
      return redirect('/')
    }
  }
}
