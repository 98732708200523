import { WIDGET_BRIDGE, WIDGET_IFRAME } from '../bridge/utils/actors'

export default async (ctx, inject) => {
  if (typeof window === 'undefined') { return }

  window.addEventListener('message', (event) => {
    if (event.data.recipient !== WIDGET_IFRAME) return
    if (event.data.sender !== WIDGET_BRIDGE) return

    if (event.data.function !== 'updateIsIframeOpen') {
      ctx.$airbrake.notifyWithNuxtContext(
        new Error(`unknown method to execute : ${event.data.function}`),
        { event },
        ctx,
      )
      return
    }

    ctx.store.commit('layout/setIsIframeOpen', event.data.params.status)
  })
}
