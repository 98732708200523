export const arrayToObject = (arr) => {
  const res = {}
  arr.forEach(([key, val]) => { res[key] = val })
  return res
}

export const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const defineGetters = (state) => {
  return arrayToObject(
    Object.keys(state).map((key) => [
      key,
      (state) => state[key],
    ])
  )
}

export const defineSetters = (state) => {
  return arrayToObject(
    Object.keys(state).map((key) => [
      'set' + capitalizeFirstLetter(key),
      (state, newValue) => (state[key] = newValue),
    ])
  )
}
