import isEmpty from 'lodash-es/isEmpty'

export default (ctx) => {
  ctx.app.router.beforeEach((to, from, next) => {
    if (!isEmpty(to.query) || isEmpty(from.query)) { return next() }

    next({
      ...to,
      query: from.query,
    })
  })
}
