export const RESET = 'RESET'

export const APPEND_TIMELINE = 'APPEND_TIMELINE'
export const APPEND_ARCHIVES = 'APPEND_ARCHIVES'

export const SET_IS_TIMELINE_LOADING = 'SET_IS_TIMELINE_LOADING'
export const SET_IS_TIMELINE_LOADED = 'SET_IS_TIMELINE_LOADED'
export const SET_ARE_ARCHIVES_LOADING = 'SET_ARE_ARCHIVES_LOADING'
export const SET_ARE_ARCHIVES_LOADED = 'SET_ARE_ARCHIVES_LOADED'
export const SET_ARE_ARCHIVES_OPEN = 'SET_ARE_ARCHIVES_OPEN'

export const SET_IS_MODAL_IMAGE_OPEN = 'SET_IS_MODAL_IMAGE_OPEN'
export const SET_MODAL_IMAGE_SRC = 'SET_MODAL_IMAGE_SRC'

// export const UPDATE_MESSAGE = 'UPDATE_MESSAGE'
export const ARCHIVE_MESSAGE = 'ARCHIVE_MESSAGE'
